export enum EventTypes {
  Load = 'load',
  Open = 'open',
  Close = 'close',
  Submit = 'submit',
  Submitted = 'submitted',
}

export enum LocalStorageKeys {
  ClosedButtonPrompt = 'closedButtonPrompt',
  VisitorId = 'visitorId',
}
