import './capture.scss';

import { EventTypes, FormConfig, LocalStorageKeys } from 'types';
import { setTheme } from 'theme';
import {
  InlineCloseWidgetIcon,
  InlinePhoneEngage,
  InlinePhoneEngageIcon,
  InlineVerseWidgetIcon,
  InlineChatBubble,
  InlineChatBoxes,
} from 'assets/images/inline-icons';
import { WidgetType } from 'utils/enums';
import { v4 as uuidv4 } from 'uuid';
import { postEvent } from 'services/core.service';

const getVisitorId = (): string | null => localStorage.getItem(LocalStorageKeys.VisitorId);
const setVisitorId = (): string => {
  const visitorId = uuidv4();
  localStorage.setItem(LocalStorageKeys.VisitorId, visitorId);

  return visitorId;
};

const visitorId = getVisitorId() || setVisitorId();
const sessionId = uuidv4();
const currentScript = document.currentScript;
const scriptDataset = currentScript?.dataset;
const formKey = scriptDataset?.formId ?? '';
let modalQueryString = `?formKey=${formKey}&visitorId=${visitorId}&sessionId=${sessionId}`;

if (scriptDataset?.sandbox) {
  modalQueryString += '&sandbox=1';
}

const referrer = window.location.href ?? '';
if (referrer) {
  modalQueryString += `&referrer=${encodeURIComponent(referrer.substring(0, 1024))}`;
}

const MOBILE_BREAK_POINT = 600;
let showButtonPrompt = false;
let showButtonPromptOnMobile = true;
// Before creating a new verseCaptureContainer, check if one already exists
const existingContainer = document.getElementById('verse-capture-container');

const closedButtonPrompt = (): boolean => !!localStorage.getItem(LocalStorageKeys.ClosedButtonPrompt);
const saveButtonPromptClosed = (): void => localStorage.setItem(LocalStorageKeys.ClosedButtonPrompt, '1');

// If it exists, remove the existing container from the document body
if (existingContainer) {
  document.body.removeChild(existingContainer);
}
const verseCaptureContainer = document.createElement('div');
verseCaptureContainer.id = 'verse-capture-container';
verseCaptureContainer.className = 'verse-capture-container';

const buttonPromptContainer = document.createElement('div');
buttonPromptContainer.id = 'verse-capture-button-prompt-container';
buttonPromptContainer.className = 'verse-capture-button-prompt-container';

const buttonPromptPhoneEngageIcon = document.createElement('span');
buttonPromptPhoneEngageIcon.classList.add('verse-capture-button-prompt-phone-engage-icon');
buttonPromptPhoneEngageIcon.innerHTML = InlinePhoneEngage;
buttonPromptContainer.appendChild(buttonPromptPhoneEngageIcon);

const buttonPromptCloseButton = document.createElement('button');
buttonPromptCloseButton.id = 'verse-capture-button-prompt-close';
buttonPromptCloseButton.className = 'verse-capture-button-prompt-close';
buttonPromptCloseButton.innerText = 'close';
buttonPromptCloseButton.ariaLabel = 'Dismiss greeting';

const buttonPrompt = document.createElement('button');
buttonPrompt.id = 'verse-capture-button-prompt';
buttonPrompt.className = 'verse-capture-button-prompt';

const modalOpenClassName = 'verse-capture--visible';
const modalOpenFullscreenClassName = 'verse-capture--visible-fullscreen';
const buttonOpenClassName = 'verse-capture-button--open';
const buttonOpenErrorClassName = 'verse-capture-button--open--error';
const buttonVisibleClassName = 'verse-capture-button--visible';
const buttonAttentionClassName = 'verse-capture-button--attention';

const buttonClosedAriaLabel = 'Text us. Click to open form';
const buttonOpenAriaLabel = 'Minimize the form';

const button = document.createElement('button');
button.type = 'button';
button.classList.add('verse-capture-button');

const DEFAULT_ICONS = ['phone-engage', 'chat-bubble', 'chat-boxes', 'verse-logo'];
const icon = document.createElement('span');

const closeIcon = document.createElement('span');
closeIcon.classList.add('verse-capture-button-close-icon');
closeIcon.innerHTML = InlineCloseWidgetIcon;
button.appendChild(closeIcon);

if (scriptDataset?.attention) {
  button.classList.add(buttonAttentionClassName);
}

button.ariaLabel = buttonClosedAriaLabel;

const modal = document.createElement('iframe');
modal.classList.add('verse-capture');
let appState = {
  isQRForm: false,
};
/*
  since capture.js always loads from the same domain
  as the actual form frontend, get the form url from
  the capture.js script tag, but default to '/' if
  there are any issues
*/
let formUrl = '/';
if (document?.currentScript && 'src' in document.currentScript) {
  formUrl = document.currentScript.src.replace('capture.js', '');
}

modal.src = `${formUrl}${modalQueryString}`;
modal.title = 'Contact Us Form';
modal.setAttribute('role', 'modal');

// used to track form error state
// to style button in error view only when opened
let displayErrorView = false;

let oofOpen = false;
const closeModal = () => {
  if (oofOpen) {
    return;
  }

  postEvent({
    formKey,
    visitorId,
    sessionId,
    referrer,
    eventType: EventTypes.Close,
  });

  button.ariaLabel = buttonClosedAriaLabel;
  button.classList.remove(buttonOpenClassName);
  button.classList.remove(buttonOpenErrorClassName);
  modal.classList.remove(modalOpenClassName);
  buttonPromptContainer.style.display = appState.isQRForm ? 'none' : 'block';
  button.style.display = appState.isQRForm ? 'none' : 'block';
};

let count = 0;
let start: number | null;
let triggered = false;
const openModal = () => {
  if (oofOpen) {
    return;
  }

  postEvent({
    formKey,
    visitorId,
    sessionId,
    referrer,
    eventType: EventTypes.Open,
  });

  button.ariaLabel = buttonOpenAriaLabel;
  modal.classList.add(modalOpenClassName);
  buttonPromptContainer.style.display = 'none';
  // if still in error state, show it when open only
  if (displayErrorView) {
    button.classList.remove(buttonOpenClassName);
    button.classList.add(buttonOpenErrorClassName);
  } else {
    button.classList.remove(buttonOpenErrorClassName);
    button.classList.add(buttonOpenClassName);
  }

  if (triggered) {
    return;
  }
  ++count;
  if (!start || Date.now() - start > 10_000) {
    start = Date.now();
    count = 1;
  } else if (count === 7) {
    triggered = true;
    oofOpen = true;
    const className = 'verse-capture-button--oof';
    button.classList.add(className);
    setTimeout(() => {
      button.classList.remove(className);
      oofOpen = false;
    }, 2000);
  }
};

const openQR = () => {
  modal.classList.add(modalOpenFullscreenClassName);
  appState.isQRForm = true;
  buttonPromptContainer.style.display = 'none';
  buttonPromptPhoneEngageIcon.style.display = 'none';
  button.style.display = 'none';
};

button?.addEventListener('click', () => {
  if (button.classList.contains(buttonOpenClassName) || button.classList.contains(buttonOpenErrorClassName)) {
    return closeModal();
  }

  openModal();
});

// hides outline on click so it only shows on keyboard tab
button?.addEventListener('mousedown', (e) => {
  e.preventDefault();
});

buttonPromptCloseButton?.addEventListener('click', () => {
  verseCaptureContainer.removeChild(buttonPromptContainer);
  showButtonPrompt = false;
  saveButtonPromptClosed();
});

// hides outline on click so it only shows on keyboard tab
buttonPromptCloseButton.addEventListener('mousedown', (e) => {
  e.preventDefault();
});

buttonPrompt?.addEventListener('click', () => {
  openModal();
});

// hides outline on click so it only shows on keyboard tab
buttonPrompt?.addEventListener('mousedown', (e) => {
  e.preventDefault();
});

window.addEventListener('resize', (event) => {
  if (showButtonPrompt && (window.innerWidth > MOBILE_BREAK_POINT || showButtonPromptOnMobile)) {
    verseCaptureContainer.appendChild(buttonPromptContainer);
  } else {
    try {
      verseCaptureContainer.removeChild(buttonPromptContainer);
    } catch {
      // expected error if buttonPrompt is not in the DOM
    }
  }
});

// capture any messages from the current page and handle the ones we care about
window.addEventListener('message', (e) => {
  try {
    const { eventName, payload } = e.data;
    switch (eventName) {
      case 'VERSE_CAPTURE_DESTROY':
        const parentElement = currentScript?.parentElement;
        if (parentElement && currentScript) {
          parentElement.removeChild(currentScript);
          document.body.removeChild(verseCaptureContainer);
        }
        break;
      case 'VERSE_WEB_FORM_SETTINGS':
        onWebFormSettings(payload);
        break;
      case 'VERSE_CAPTURE_RESET':
        closeModal();
        modal.contentWindow?.postMessage({ eventName: 'VERSE_CAPTURE_RESET', payload }, '*');
        break;
      case 'VERSE_LIVE_FORM_SETTINGS':
        modal.contentWindow?.postMessage({ eventName: 'VERSE_LIVE_FORM_SETTINGS', payload }, '*');
        break;
      case 'VERSE_CAPTURE_CLOSE':
        closeModal();
        break;
      case 'VERSE_CAPTURE_OPEN_QR':
        openQR();
        break;
      case 'VERSE_CAPTURE_OPEN':
        openModal();
        break;
      case 'VERSE_CAPTURE_SHOW_CONFIRMATION':
        modal.contentWindow?.postMessage({ eventName: 'VERSE_CAPTURE_SHOW_CONFIRMATION', payload }, '*');
        break;
      case 'VERSE_CAPTURE_SHOW_QR_CONFIRMATION':
        modal.contentWindow?.postMessage({ eventName: 'VERSE_CAPTURE_SHOW_QR_CONFIRMATION', payload }, '*');
        break;
      case 'VERSE_CAPTURE_HEIGHT_CHANGE':
        modal.style.maxHeight = payload;
        break;
      case 'VERSE_CAPTURE_ERROR':
        displayErrorView = true;
        button.classList.remove(buttonOpenClassName);
        button.classList.add(buttonOpenErrorClassName);
        break;
    }
  } catch {
    // it will error if it's an event we don't handle which is fine
  }
});

// valid custom icon urls start with 'https://verse-customer-hosting'
// and end in a valid img type: svg|jpg|jpeg|png
// Larry ensures only valid urls are saved to agentSettings so at this point
// the url should be good, but check just in case
const isValidCustomButtonIcon = (icon: string) => {
  const reg = /^https:\/\/verse-customer-hosting.*\.(svg|jpg|jpeg|png)$/i;
  return !DEFAULT_ICONS.includes(icon) && reg.test(icon);
};

// show button only after we get the form settings from child frame
const onWebFormSettings = (FormConfig: FormConfig) => {
  if (!FormConfig) {
    console.error('No FormConfig provided');
    return;
  }

  showButtonPromptOnMobile = FormConfig.showButtonPromptOnMobile === 1;
  appState.isQRForm = FormConfig.type === WidgetType.QR;

  try {
    showButtonPrompt = !closedButtonPrompt();
    if (FormConfig.type !== WidgetType.QR) {
      if (showButtonPrompt && FormConfig.buttonPrompt?.length) {
        // setting font family here for future case of custom user fonts
        buttonPrompt.style.fontFamily = 'Lato, Arial, serif';
        buttonPrompt.ariaLabel = FormConfig.buttonPrompt;
        buttonPrompt.innerText = FormConfig.buttonPrompt;
        buttonPromptCloseButton.tabIndex = 1;
        buttonPrompt.tabIndex = 2;
        button.tabIndex = 3;
        buttonPromptContainer.appendChild(buttonPromptCloseButton);
        buttonPromptContainer.appendChild(buttonPrompt);
        if (window.innerWidth > MOBILE_BREAK_POINT || showButtonPromptOnMobile) {
          verseCaptureContainer.appendChild(buttonPromptContainer);
        }
      } else {
        button.tabIndex = 1;
        try {
          verseCaptureContainer.removeChild(buttonPromptContainer);
        } catch {
          // expected error if buttonPrompt is not in the DOM
        }
      }
      button.classList.add(buttonVisibleClassName);

      if (FormConfig.icon === 'chat-bubble' || FormConfig.icon === 'phone-engage') {
        icon.classList.add('verse-capture-button-icon-stroke');
      } else {
        icon.classList.add('verse-capture-button-icon');
      }

      if (FormConfig.icon && isValidCustomButtonIcon(FormConfig.icon)) {
        const customImg = document.createElement('img');
        customImg.src = FormConfig.icon;
        customImg.height = 60;
        customImg.width = 60;
        icon.innerHTML = '';
        icon.append(customImg);
      } else {
        switch (FormConfig.icon) {
          case 'phone-engage':
            icon.innerHTML = InlinePhoneEngageIcon;
            break;
          case 'chat-bubble':
            icon.innerHTML = InlineChatBubble;
            break;
          case 'chat-boxes':
            icon.innerHTML = InlineChatBoxes;
            break;
          default:
            icon.innerHTML = InlineVerseWidgetIcon;
            break;
        }
      }

      button.appendChild(icon);
    }
    setTheme(FormConfig, verseCaptureContainer);
  } catch (error) {
    console.error('Unable to setup verse capture', error);
  }
};

verseCaptureContainer.appendChild(button);
verseCaptureContainer.appendChild(modal);
document.body.appendChild(verseCaptureContainer);

postEvent({
  formKey,
  visitorId,
  sessionId,
  referrer,
  eventType: EventTypes.Load,
});
